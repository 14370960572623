import React from "react";
import "./ask-auto-question.scss";
import { profilePhoto } from "../../../../assets/images";

const AskAutoQuestion = ({ query, img }: Props) => {
  console.log(query)
  return (
    <div className="ask-auto-question">
      <div className="ask-auto-question-img">
        <img src={img || profilePhoto} alt="" />
      </div>
      <h5>{query || "Error loading question"}</h5>
    </div>
  );
};

export default AskAutoQuestion;

type Props = {
  query?: string;
  img?: string;
};
