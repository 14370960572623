import axios from "axios";
import { DiagnosisProps, EditPayloadType, ProcedureProps } from "../containers";
import { apiClient } from "./apiClient";

const getSummary = async (payload: IPriorAuthGetSummaryPayload) => {
  const { data } = await apiClient.post<IPriorAuthGetSummaryResponse>("/chat/query", payload);
  return { data };
};

const submitReview = async (payload: ISubmitReviewPayload, documentUUID: string) => {
  const { data } = await apiClient.post<ISubmitReviewResponse>(
    `/prior-auth/case-review/${documentUUID}`,
    payload
  );
  return { data };
};

const generateGuidelineCompliance = async (
  documentUUID: string,
  diagnosisCode?: DiagnosisProps,
  procedureCode?: ProcedureProps,
  callFlag?: string
) => {
  const payload = callFlag
    ? {
        diagnoses: {
          code: diagnosisCode?.diagnosisCode,
          description: diagnosisCode?.diagnosisDescription,
        },
        procedure: {
          code: procedureCode?.procedureCode,
          description: procedureCode?.procedureDescription,
        },
        call_flag: callFlag,
      }
    : {
        diagnoses: {
          code: diagnosisCode?.diagnosisCode,
          description: diagnosisCode?.diagnosisDescription,
        },
        procedure: {
          code: procedureCode?.procedureCode,
          description: procedureCode?.procedureDescription,
        },
      };
  const res = await apiClient.post(`/prior-auth/guideline-compliance/${documentUUID}`, payload);
  return res;
};

const generateAuthorizationOverview = async (
  documentUUID: string,
  diagnosisCode?: DiagnosisProps,
  procedureCode?: ProcedureProps,
  selectedCheckboxes?: string[]
) => {
  const payload = selectedCheckboxes?.length
    ? {
        diagnoses: {
          code: diagnosisCode?.diagnosisCode,
          description: diagnosisCode?.diagnosisDescription,
        },
        procedure: {
          code: procedureCode?.procedureCode,
          description: procedureCode?.procedureDescription,
        },
        call_flag: selectedCheckboxes[0].toUpperCase().replace(/\s/g, "_"),
      }
    : {
        diagnoses: {
          code: diagnosisCode?.diagnosisCode,
          description: diagnosisCode?.diagnosisDescription,
        },
        procedure: {
          code: procedureCode?.procedureCode,
          description: procedureCode?.procedureDescription,
        },
      };
  const res = await apiClient.post<ResponseData>(
    `/prior-auth/authorization-overview/${documentUUID}`,
    payload
  );
  return res;
};

const fetchAnswerForAskAuto = async (q: string, documentUuid: string, containerId: number) => {
  const { data } = await apiClient.post<IAskAutoChatResponse>(`/chat/query`, {
    q,
    metadataFilter: 0,
    containerId,
    qaId: crypto.randomUUID(),
    documentUuid,
    docVariant: "prior-auth",
    guideline: "carelon",
  });
  return { data };
};

const submitFeedback = async (payload: FeedbackPayload) => {
  const { data } = await apiClient.post<FeedbackResponse>("/feedback", payload);
  return data;
};

const editAuthorizationData = async (documentUUID: string, payload: EditPayloadType) => {
  const { data } = await apiClient.put(
    `/prior-auth/authorization-overview/${documentUUID}`,
    payload
  );
  return data;
};

// ---------- Module Export ----------
export const priorAuthService = {
  getSummary,
  submitReview,
  generateGuidelineCompliance,
  generateAuthorizationOverview,
  fetchAnswerForAskAuto,
  submitFeedback,
  editAuthorizationData,
};

// ---------- Interfaces ----------
interface IPriorAuthGetSummaryPayload {
  q: string;
  metadataFilter: number;
  containerId: number;
  qaId: string;
  documentUuid: string;
  docVariant: string;
}

interface ISubmitReviewPayload {
  status: string;
}

interface ISubmitReviewResponse {
  data: {
    message: string;
  };
  status: string;
  transaction_id: string;
}

interface IPriorAuthGetSummaryResponse {
  data: {
    answer: string;
    query: string;
    sources: {
      documentUUID: string;
      pages: {
        offsets: {
          begin: number;
          end: number;
        }[];
        pageNo: number;
      }[];
      fileName: string;
    }[];
    logId: number;
    qaId: string;
  };
  status: string;
  transaction_id: string;
}

type Offset = {
  begin: number;
  end: number;
};

type Page = {
  offsets: Offset[];
  pageNo: number;
};

export type GuidelineSource = {
  documentUUID: string;
  pages: Page[];
  category: string;
};

export type AdjudicationSummaryItem = {
  compliance_status: boolean;
  guideline_description: string;
  observation: string;
};

type IGuidelineComplianceResponse = {
  data: {
    guideline_sources: GuidelineSource[];
    adjudication_summary: AdjudicationSummaryItem[];
  };
  status: string;
  transaction_id: string;
};

type Data = {
  overview: string;
  adjudication_summary: string[];
  summaryId: number;
};

type ResponseData = {
  data: Data;
  status: "OK" | string; // Adjust this if there are other possible status values
  transaction_id: string;
};

export type IAskAutoChatDataRes = {
  answer: string;
  query: string;
  sources: {
    category: string;
    documentUUID: string;
    pages: {
      offsets: {
        begin: number;
        end: number;
      }[];
      pageNo: number;
    }[];
    fileName: string;
  }[];
  logId: number;
  qaId: string;
};

export type IAskAutoChatResponse = {
  data: IAskAutoChatDataRes;
  status: string;
  transaction_id: string;
};

export type FeedbackPayload = {
  feedback: string;
  feedbackType: string;
  logId: number;
  qaId: string;
};

export type FeedbackResponse = {
  data: {
    message: string;
  };
  status: string;
  transaction_id: string;
};
