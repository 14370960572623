import React, { useCallback, useEffect, useState } from "react";
import UTIF, { IFD } from "utif";
import axios from "axios";
import { AppPagination } from "../app-pagination/AppPagination";
import "./tiff-viewer.scss";
import { Loading } from "../Loading";
import { Button, Progress } from "antd";
import { downloadPDF } from "../../utils";
import { DownloadOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { AxiosProgressEvent } from "axios";

const getImageFromTiffPage = (data: any, ifd: IFD, index: number) => {
  UTIF.decodeImage(data, ifd);
  const rgba = UTIF.toRGBA8(ifd);
  const canvas = document.createElement("canvas");
  canvas.width = ifd.width;
  canvas.height = ifd.height;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    const img = ctx.createImageData(ifd.width, ifd.height);
    img.data.set(rgba);
    ctx.putImageData(img, 0, 0);
  }

  const dataUrl = canvas.toDataURL("image/png");
  const imageEle = <img key={index} src={dataUrl} alt={`Page ${index + 1}`} />;
  return imageEle;
  // return canvas;
};

interface Props {
  tiff: any;
  pageInfo?: {
    width?: number;
    height?: number;
    scale?: number;
  };
  currPage: number;
  setCurrPage: (page: number) => void;
}

export const TIFFViewer = (props: Props) => {
  const { tiff, currPage, setCurrPage, pageInfo } = props;

  const [tiffs, setTiffs] = React.useState<JSX.Element[]>([]);
  const [total, setTotal] = React.useState(0);
  const [currTiff, setCurrTiff] = React.useState<JSX.Element>(<></>);
  const [loadingProgress, setLoadingProgress] = React.useState(0);
  const [scale, setScale] = useState(pageInfo?.scale || 1);
  const minScale = 0.5;
  const maxScale = 2;

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, maxScale));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, minScale));
  };

  const handleFileDownload = async () => {
    await downloadPDF(tiff);
  };
  console.log(1,'tiff',tiff)

  function imgLoaded(e: any) {
    const data = e.target.response;
    const ifds = UTIF.decode(data);
    console.log(1,'tiff',tiff)
    const pages = ifds.length;
    const _tiffs = [] as JSX.Element[];
    for (let index = 0; index < pages; index++) {
      const img = getImageFromTiffPage(data, ifds[index], index);
      _tiffs.push(img);
      if (index === 0) setCurrTiff(img);
    }
    setTotal(_tiffs.length);
    setTiffs(_tiffs);
  }

  const displayTIFF = useCallback(async (url: string) => {
    const response = await axios.get(url, {
      responseType: "arraybuffer",
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        const totalProgress = progressEvent.total || 1;
        const percentage = Math.round((progressEvent.loaded * 100) / totalProgress);
        setLoadingProgress(percentage);
      },
    });

    console.log(response.data);
    imgLoaded({ target: { response: response.data } });
  }, []);

  useEffect(() => {
    displayTIFF(tiff);
  }, [displayTIFF, tiff]);

  useEffect(() => {
    if (total > 0) {
      setCurrTiff(tiffs[currPage - 1]);
    }
  }, [currPage, tiffs, total]);

  return (
    <div className={"e-viewer e-content"} id="tiff-container">
      {total === 0 ? (
        loadingProgress < 100 ? (
          <Progress percent={Math.ceil(loadingProgress)} showInfo />
        ) : (
          <Loading tip="Processing file..." />
        )
      ) : (
        <>
          <div className="flex-center e-pdf-pagination">
            <div className="flex-center e-pdf-pagination">
              <AppPagination
                total={total * 10}
                simple
                current={currPage}
                onChange={(page) => setCurrPage(page)}
                showSizeChanger={false}
                //scale={scale}
              />
            </div>
            <div className="button-container">
              <Button
                icon={<ZoomOutOutlined />}
                onClick={handleZoomOut}
                disabled={scale <= minScale}
              />
              <span>{scale ? `${Math.round(scale * 100)}%` : "N/A"}</span>
              <Button
                icon={<ZoomInOutlined />}
                onClick={handleZoomIn}
                disabled={scale >= maxScale}
              />
              <Button icon={<DownloadOutlined />} onClick={handleFileDownload} />
            </div>
          </div>
          <div className="pdf-container">
            <div style={{ transform: `scale(${scale})`, transformOrigin: "top left" }}>
              {currTiff}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
