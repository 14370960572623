import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import DeleteConfirmation from "../../containers/modals/delete-confirmation/DeleteConfirmation";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { useProject } from "../../hooks";
import { updateDeleteConfirmation } from "../../store";
import { updateCanFetchContainers } from "../../store/file-folder-upload/slice";
import { CloseOutlined, PushpinFilled, PushpinOutlined } from "@ant-design/icons";
import { IProject } from "../../interfaces/project";
import { PAGE_URL } from "../../utils";
import { useNavigate } from "react-router-dom";
interface Props {
  project: IProject;
}

export const ProjectActions = ({ project }: Props) => {
  const dispatch = useDispatch();
  const { deleteProject, pinProject } = useProject();
  const [confirmModal, setConfirmModal] = useState(false);
  const navigate = useNavigate();
  const handleDeleteProject = async () => {
    try {
      await deleteProject(project.id);
      dispatch(updateDeleteConfirmation(false));
      dispatch(updateCanFetchContainers(true));
      setConfirmModal(false);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const handleEditClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(`${PAGE_URL.EDIT_PROJET}/${project.id}`, {
        state: {
          selectedProjectId: project.id,
          selectedProjectName: project.name,
          selectedProjectGuidelines: project.guidelines,
          selectedProjectsDescription: project.description,
          isPriorAuthProject: project.isPriorAuth ? true : false,
        },
      });
    },
    [navigate, project]
  );

  const handlePinProject = async (e: any) => {
    try {
      e.stopPropagation();
      const pinStatus = project.isPinned;
      await pinProject(project.id, pinStatus);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="editDelete flex gp">
      <div className="flex" onClick={(event: any) => handleEditClick(event)}>
        <EditIcon />
      </div>
      {!project.isPinned ? (
        <PushpinOutlined style={{ fontSize: "15px" }} onClick={handlePinProject} />
      ) : (
        <PushpinFilled style={{ fontSize: "15px" }} onClick={handlePinProject} />
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setConfirmModal(true);
        }}
        style={{ marginTop: "5px" }}
      >
        <DeleteIcon />
      </div>
      {confirmModal && (
        <DeleteConfirmation
          visibility={confirmModal}
          handleClose={() => {
            setConfirmModal(false);
          }}
          handleDelete={handleDeleteProject}
        />
      )}
    </div>
  );
};
